<template>
  <section
    class="container-md"
    :class="{ 'mt-3': isMobileView, 'mt-6': !isMobileView }"
  >
<p class="title mb-6">Booking Details</p>
  <div class="columns mb-6 is-align-items-start">
    <div class="mb-5 column is-4 is-one-quarter-fullhd search-container">
      <b-field label="Email">
        <b-input
          expanded
          placeholder="Enter your email"
          v-model="email"
          type="email"
          @input="updateQuery"
        >
        </b-input>
      </b-field>
      <b-field label="Order number">
        <b-input
          expanded
          placeholder="Enter your order number"
          v-model="ticketId"
          @input="updateQuery"
        >
        </b-input>
      </b-field>
      <b-button
        class="mt-5"
        @click.prevent="getTicketData()"
        type="is-primary"
        rounded
        :loading="isSearching"
        expanded
        :disabled="!isButtonEnabled"
      >
        Search ticket
      </b-button>
    </div>
    <div class="column"   :class="{ 'ml-6': !isMobileView }">
      <b-loading v-if="isSearching" :can-cancel="false"></b-loading>
      <section v-else>
        <div v-if="ticketExists">
          <TicketTemplate
            class="mb-6 ticket-section"
            :ticket="ticket"
            :selected-pass-data="selectedPassData"
          />
          <p class="mb-4 is-size-6 has-text-weight-bold card-header-title">Route</p>
          <TourRouteInfo :stops="selectedPassData.stops" class="mb-6 pr-4 pl-4" />
          <section v-if="selectedPassData?.id">
            <b-collapse
              animation="slide"
              v-for="(collapse, index) of collapsableContent"
              :key="index"
              :open="isOpen == index"
              @open="isOpen = index"
            >
              <template #trigger="props">
                <div class="card-header" role="button">
                  <p class="card-header-title">
                    {{ collapse.title }}
                  </p>
                  <a class="card-header-icon" v-show="props.open">
                    <b-icon icon="chevron-up"></b-icon>
                  </a>
                  <a class="card-header-icon" v-show="!props.open">
                    <b-icon icon="chevron-down"></b-icon>
                  </a>
                </div>
              </template>
              <div class="card-content">
                <div>
                  <span v-html="collapse.text"> {{ collapse.text }}</span>
                </div>
              </div>
            </b-collapse>
          </section>
        </div>

        <div v-else>
          We were not able to find the ticket. Need help? Contact us at
          support@guiders.pt
        </div>
      </section>
    </div>
  </div>
  </section>
</template>

<script>
import TicketTemplate from "./Vendor/TicketTemplate.vue";
import { mapActions, mapState } from "vuex";
import { ActivitiesStates, ActivitiesActions } from "./store/Storetypes";
import { getHoursAndMinutes } from "./helpers";
import { db } from "./firebase";
import { HelpersMixin } from "./mixins";
import TourRouteInfo from "./components/TourRouteInfo.vue";
export default {
  name: "TicketDetails",
  components: { TicketTemplate, TourRouteInfo },
  mixins: [HelpersMixin],
  data() {
    return {
      selectedPassData: {},
      getHoursAndMinutes,
      showAllTickets: false,
      ticketExists: false,
      email: this.$route.query.email || "",
      ticketId: this.$route.query.orderNumber || "",
      isSearching: true,
      ticket: {},
      previousTicketId: undefined,
      isButtonEnabled: false,
      passes: [],
    };
  },
  mounted() {
    this.getActivities();
    this.getBoughtPasses();
   
    if (this.isValidEmail(this.email) && this.ticketId.length) {
      this.isButtonEnabled = true;
    }
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(newQuery) {
        this.email = newQuery.email || "";
        this.ticketId = newQuery.orderNumber || "";
      },
    },
    email() {
      if (this.isValidEmail(this.email)) {
        this.isButtonEnabled = true;
      } else {
        this.isButtonEnabled = false;
      }
    },
    ticketId() {
      if (this.ticketId.trim().length > 1) {
        this.isButtonEnabled = true;
      } else {
        this.isButtonEnabled = false;
      }
    },
  },
  methods: {
    ...mapActions({
      getActivities: ActivitiesActions.GET_ACTIVITIES,
      retrieveTicketData: ActivitiesActions.GET_TICKET_DATA,
    }),
    getBoughtPasses() {
      db.firestore()
        .collection("passes")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.passes.push(doc.data());
          });
          this.getTicketData();
        })
        .catch((err) => {
          this.$buefy.toast.open({
            duration: 10000,
            message: err.message,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
    updateTicketData() {
      const tickets = [...this.ticket.tickets];
      tickets.map((ticket, index) => {
       if (new Date() >  new Date(ticket?.expireDate)) {
          tickets[index] = {
            ...tickets[index],
            isTicketValid: false,
          };
        }
      });

      if (JSON.stringify(this.ticket.tickets) !== JSON.stringify(tickets)) {
        db.firestore()
          .collection("passes")
          .doc(this.ticketId)
          .update({ tickets: tickets })
          .catch(() => {
            this.$buefy.toast.open({
              duration: 10000,
              message:
                "There has been an error updating this ticket. Try again later.",
              position: "is-bottom",
              type: "is-danger",
            });
          });
        this.ticket.tickets = tickets;
      }
    },
    setSelectedPassData() {
      const selectedActivityIndex = this.activities.findIndex(
        (activity) => activity.id === this.ticket.selectedPassId
      );
      if (selectedActivityIndex < 0) {
        this.selectedPassData = {};
        this.isSearching = false;
        return;
      }
      this.selectedPassData = this.activities[selectedActivityIndex];
      this.isSearching = false;
    },
    getTicketData() {
      this.isSearching = true;
      this.ticket = {};
      this.ticketExists = false;
      this.selectedPassData = {};

      this.ticket = this.passes.find(
        (pass) =>
          pass.ticketId === this.ticketId && pass.buyerData.email === this.email
      );
      this.previousTicketId = this.ticketId;

      if (this.ticket?.ticketId) {
        this.ticketExists = true;
        this.setSelectedPassData();
        this.updateTicketData();
      } else {
        this.isSearching = false;
      }
    },
    updateQuery() {
      this.$router.push({
        path: this.$route.path,
        query: {
          email: this.email || undefined,
          orderNumber: this.ticketId || undefined,
        },
      });
    },
  },
  computed: {
    ...mapState({
      activities: (state) => state.activities[ActivitiesStates.ACTIVITIES],
      // ticket: (state) => state.activities[ActivitiesStates.TICKET_DATA],
    }),
    buttonIcon() {
      return this.showAllTickets ? "chevron-down" : "chevron-up";
    },
    collapsableContent() {
      if (!this.selectedPassData) return [];
      const content = [];
      if (this.selectedPassData?.whatToExpect.length) {
        content.push({
          title: "What to expect",
          text: this.selectedPassData?.whatToExpect,
        });
      }
      if (this.selectedPassData?.additionalInfo) {
        content.push({
          title: "Additional information",
          text: this.selectedPassData?.additionalInfo,
        });
      }
      if (this.selectedPassData?.notSuitableFor) {
        content.push({
          title: "Not suitable for",
          text: this.selectedPassData?.notSuitableFor,
        });
      }
      if (this.selectedPassData?.accessibility) {
        content.push({
          title: "Accessibility",
          text: this.selectedPassData?.accessibility,
        });
      }

      if (this.selectedPassData?.cancellationPolicy) {
        content.push({
          title: "Cancellation Policy",
          text: this.selectedPassData?.cancellationPolicy,
        });
      }

      return content;
    },
  },
};
</script>

<style lang="scss" scoped>

.title{
  font-size: 1.25rem;
  font-weight: 600;
}
::v-deep {
  .search-container {
    margin: 0.5rem;
    border-radius: 12px;
    padding: 28px 20px;
    border: 1px solid #dee5f4;
    .input {
      border: 1px solid #dee5f4;
    }
  }
}

.ticket-section {
  justify-self: center;
}
.collapse-trigger {
  padding: 12px 0px !important;
}

.collapse .collapse-trigger .icon {
  transform: translateY(4px);
}

.collapse-content {
  margin-top: 12px;
}

.card-header {
  box-shadow: none;
  border-bottom: 1px solid #dee5f4;
}

.card-content {
  font-weight: 400;
  color: rgba(14, 61, 77, 0.5);
  white-space: pre-line;
  font-size: 0.95rem;
}
@media screen and (min-width: 1700px) {
  .container-md {
    padding: 0 25vh;
  }
}

@media screen and (min-width: 1400px) {
  .container-md {
    padding: 0 12vh;
  }
}
</style>
