var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GmapMap',{staticStyle:{"width":"100%","height":"500px"},attrs:{"center":_vm.mapCenter,"zoom":15,"map-type-id":"roadmap"}},[_vm._l((_vm.parsedLocations),function(location,index){return _c('GmapMarker',{key:index,attrs:{"position":location.position,"clickable":true,"label":{
        text: (index + 1).toString(),
        color: '#015840',
        fontSize: '14px',
        fontWeight: 'bold',
      },"icon":{
        url: require('../assets/green-circle.png')
      }},on:{"click":function($event){return _vm.openInfoWindow(index)}}},[_c('GmapInfoWindow',{attrs:{"opened":_vm.infoWindowIndex === index}},[_c('div',[_c('h3',[_vm._v(_vm._s(location.title))]),_c('p',[_vm._v(_vm._s(location.info))])])])],1)}),(_vm.parsedLocations.length > 1)?_c('GmapPolyline',{attrs:{"path":_vm.parsedLocations.map((loc) => loc.position),"options":{
        strokeColor: '#4E070B',
        strokeOpacity: 1.0,
        strokeWeight: 3,
      }}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }