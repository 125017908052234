<template>
  <section>
    <b-field class="is-flex mb-6">
      <span class="mr-2" :class="{ 'has-text-weight-semibold': !isMapView }"
        ><b-icon class="mr-2" icon="list-ul"></b-icon>List view
      </span>
      <b-switch v-model="isMapView" type="is-primary">
        <span :class="{ 'has-text-weight-semibold': isMapView }"
          ><b-icon class="mr-2 ml-1" icon="map" pack="far"></b-icon>Map view
        </span>
      </b-switch>
    </b-field>

    <RouteMapView :locations="stops" v-if="isMapView" />

    <div class="rightbox" v-if="!isMapView">
      <div class="rb-container">
        <ul class="rb">
          <li
            class="rb-item"
            ng-repeat="itembx"
            v-for="(stop, index) in stops"
            :key="index"
          >
            <div class="item-title mb-2">{{ stop.title }}</div>
            <img
              :src="stop.imageUrl"
              v-if="stop.imageUrl"
              class="mt-3 mb-3 stop--image"
            />
            <a
              target="_blank"
              class="direction-link mt-4"
              :href="stop.direction"
            >
              <img src="../assets/location-icon.svg" class="mr-1 icon" />
              Get directions
            </a>
            <div class="item-subtitle">
              {{ stop.description }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import RouteMapView from "./RouteMapView.vue";
export default {
  data() {
    return {
      isMapView: true,
    };
  },
  components: {RouteMapView},
  props: {
    stops: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.rb-container ul.rb li:last-child {
  padding-bottom: 0.5rem;
}

.rb-container {
  margin: 24px;
  display: block;
  position: relative;
}

.rb-container ul.rb {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.rb-container ul.rb li {
  list-style: none;
  margin: auto;
  margin-left: 0;
  min-height: 50px;
  border-left: 5px dotted #dedede;
  padding: 0 0 50px 30px;
  position: relative;
}

.rb-container ul.rb li:last-child {
  border-left: 0;
  &::before {
    left: -9px;
  }
}

.rb-container ul.rb li::before {
  position: absolute;
  left: -14px;
  top: 0;
  content: " ";
  border: 9px solid #0e3d4d;
  border-radius: 500%;
  background: #fff;
  height: 24px;
  width: 24px;
  transition: all 500ms ease-in-out;
}

.rb-container ul.rb li:hover::before {
  border-color: #0e3d4dd1;
  transition: all 1000ms ease-in-out;
}

.direction-link {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    background-color: #cef54642;
    width: fit-content;
    padding: 6px 15px;
    border-radius: 38px;
  }
</style>
