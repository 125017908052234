<template>
  <div>
    <GmapMap
      :center="mapCenter"
      :zoom="15"
      map-type-id="roadmap"
      style="width: 100%; height: 500px"
    >
      <GmapMarker
        v-for="(location, index) in parsedLocations"
        :key="index"
        :position="location.position"
        :clickable="true"
        @click="openInfoWindow(index)"
        :label="{
          text: (index + 1).toString(),
          color: '#015840',
          fontSize: '14px',
          fontWeight: 'bold',
        }"
        :icon="{
          url: require('../assets/green-circle.png')
        }"
      >
        <!-- Show InfoWindow only for the clicked marker -->
        <GmapInfoWindow
        :opened="infoWindowIndex === index"
        >
        <div>
            <h3>{{ location.title }}</h3>
            <p>{{ location.info }}</p>
          </div>
        </GmapInfoWindow>
      </GmapMarker>

      <!-- Line Connecting Markers -->
      <GmapPolyline
        v-if="parsedLocations.length > 1"
        :path="parsedLocations.map((loc) => loc.position)"
        :options="{
          strokeColor: '#4E070B',
          strokeOpacity: 1.0,
          strokeWeight: 3,
        }"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mapCenter: { lat: 38.71562757677738, lng: -9.132475645446782 }, // Default center (Lisbon)
      infoWindowIndex: null, // Track which info window is open
    };
  },
  computed: {
    parsedLocations() {
      return this.locations
        .map(({ direction, title, info }) => {
          const position = this.extractLatLngFromUrl(direction);
          return position ? { position, title, info } : null;
        })
        .filter((loc) => loc); // Remove null values if parsing fails
    },
  },
  methods: {
    extractLatLngFromUrl(direction) {
      const match = direction.match(/@([-0-9.]+),([-0-9.]+)/);
      return match
        ? { lat: parseFloat(match[1]), lng: parseFloat(match[2]) }
        : null;
    },
    openInfoWindow(index) {
      this.infoWindowIndex = index;
    },
  },
};
</script>
